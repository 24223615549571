import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import CustomFieldValue from "@mapmycustomers/shared/types/customField/CustomFieldValue";
import { CustomFieldError } from "@mapmycustomers/shared/types/customField/CustomFieldValuesUpsertResponse";
import { Company, Deal, Group, Person } from "@mapmycustomers/shared/types/entity";
import { RawFile } from "@mapmycustomers/shared/types/File";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";

import { DealPayload } from "@app/store/api/ApiService";
import ChangeAssociationsPayload from "@app/store/associations/ChangeAssociationsPayload";
import { NextAssociationsState } from "@app/store/associations/getNextAssociationsState";
import FileListItem from "@app/types/FileListItem";

export const showGlobalCreateDealModal = createAction("@component/globalCreateDealModal/show")<{
  showPostCreatePrompt?: boolean;
} | void>();

export const hideGlobalCreateDealModal = createAction(
  "@component/globalCreateDealModal/hide"
)<void>();

export const initialize = createAsyncAction(
  "@component/createDealModal/initialize/request",
  "@component/createDealModal/initialize/success",
  "@component/createDealModal/initialize/failure"
)<
  { fixedCompany?: Company; fixedPerson?: Person },
  {
    associationsState: Pick<NextAssociationsState, "availableCompanies" | "availablePeople">;
    fileGroupId: string;
  },
  void
>();

export const changeAssociatedEntities = createAsyncAction(
  "@component/createDealModal/changeAssociatedEntities/request",
  "@component/createDealModal/changeAssociatedEntities/success",
  "@component/createDealModal/changeAssociatedEntities/failure"
)<
  Pick<ChangeAssociationsPayload, "company" | "person">,
  Pick<NextAssociationsState, "availableCompanies" | "availablePeople">,
  void
>();

export const createDeal = createAsyncAction(
  "@component/createDealModal/createDeal/request",
  "@component/createDealModal/createDeal/success",
  "@component/createDealModal/createDeal/failure"
)<
  {
    callback?: (newDeal?: Deal) => void;
    customFieldsValues: CustomFieldValue[];
    customFieldsValueValidateCallback?: (customFieldErrors: CustomFieldError[]) => void;
    deal: DealPayload;
    groupsIdsToAdd: Array<Group["id"]>;
    layoutId: FormLayout["id"];
  },
  Deal,
  Record<string, string>
>();

export const uploadDealFiles = createAsyncAction(
  "@component/createDealModal/uploadDealFiles/request",
  "@component/createDealModal/uploadDealFiles/success",
  "@component/createDealModal/uploadDealFiles/failure"
)<
  {
    callback?: (filesList: FileListItem[]) => void;
    files: (Blob | File)[];
  },
  FileListItem[],
  void
>();

export const removeDealFile = createAsyncAction(
  "@component/createDealModal/removeDealFile/request",
  "@component/createDealModal/removeDealFile/success",
  "@component/createDealModal/removeDealFile/failure"
)<RawFile, RawFile["id"], void>();

export const clearAllUploadedDealFiles = createAction(
  "@component/createDealModal/clearAllUploadedDealFiles"
)<void>();

export const relateUnrelateEntities = createAsyncAction(
  "@component/createDealModal/relateUnrelateEntities/request",
  "@component/createDealModal/relateUnrelateEntities/success",
  "@component/createDealModal/relateUnrelateEntities/failure"
)<
  {
    associatedCompany?: Company;
    associatedPerson?: Person;
    failureCallback?: () => void;
    isPersonCorrectlyRelatedToCompany: boolean;
    successCallback?: () => void;
  },
  {
    associationsState: Pick<NextAssociationsState, "availableCompanies" | "availablePeople">;
    person?: Person;
    unrelate: boolean;
  },
  void
>();

export type Actions = ActionType<
  | typeof changeAssociatedEntities
  | typeof clearAllUploadedDealFiles
  | typeof createDeal
  | typeof hideGlobalCreateDealModal
  | typeof initialize
  | typeof relateUnrelateEntities
  | typeof removeDealFile
  | typeof showGlobalCreateDealModal
  | typeof uploadDealFiles
>;
