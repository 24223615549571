import { defineMessages } from "react-intl";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { EntityType, Person } from "@mapmycustomers/shared/types/entity";

import AddressField from "@app/util/fieldModel/impl/AddressField";
import FirstNameField from "@app/util/fieldModel/impl/FirstNameField";
import TerritoryField from "@app/util/fieldModel/impl/TerritoryField";

import getEntityFilter from "../filters/EntityFilter";

import ColorField from "./impl/ColorField";
import CountryField from "./impl/CountryField";
import DateField from "./impl/DateField";
import DateTimeField from "./impl/DateTimeField";
import FieldModel from "./impl/FieldModel";
import { createField } from "./impl/fieldModelUtil";
import { listFormatter } from "./impl/fieldUtil";
import FrequencyDaysLeft from "./impl/FrequencyDaysLeft";
import FrequencyIntervalField from "./impl/FrequencyIntervalField";
import FrequencyStatusField from "./impl/FrequencyStatusField";
import GroupsField from "./impl/GroupsField";
import MapLocationField from "./impl/MapLocationField";
import NameField from "./impl/NameField";
import NestedAddressField from "./impl/NestedAddressField";
import NoCheckInField from "./impl/NoCheckInField";
import NoContactField from "./impl/NoContactField";
import NotesField from "./impl/NotesField";
import NoVerifiedCheckInField from "./impl/NoVerifiedCheckInField";
import RegionField from "./impl/RegionField";
import RoutesField from "./impl/RoutesField";
import SourceField from "./impl/SourceField";
import TeamField from "./impl/TeamField";
import UniqueIdField from "./impl/UniqueIdField";
import UserField from "./impl/UserField";
import ViewAsField from "./impl/ViewAsField";

export enum PersonFieldName {
  NAME = "name", // fullName
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  ADDRESS = "address",
  CITY = "city",
  REGION = "region",
  POSTAL_CODE = "postalCode",
  COUNTRY = "countryCode",
  PHONE = "phone",
  CRM_LINK = "crmLink",
  EMAIL = "email",
  FILES = "files",
  GROUPS = "groups",
  FREQUENCY_INTERVAL = "cadenceInterval",
  DAYS_LEFT_IN_FREQUENCY = "daysLeftInFrequency",
  COLOR = "color",
  CREATOR = "creator",
  CREATOR_NAME = "creatorName",
  CREATOR_EMAIL = "creator.username",
  USER = "user",
  OWNER_NAME = "ownerName",
  OWNER_EMAIL = "user.username",
  NEXT_ACTIVITY = "upcomingActivity.startAt",
  LAST_ACTIVITY_DATE = "lastCompletedActivity.completedAt",
  LAST_ACTIVITY_TYPE = "lastCompletedActivity.activityType",
  LAST_ACTIVITY_NAME = "lastCompletedActivity.name",
  FREQUENCY_STATUS = "cadenceDaysOut",
  NO_CONTACT_IN = "noContactDaysOut",
  NOTES = "notes",
  UNIQUE_ID = "importId",
  GEOPOINT = "geoPoint",
  LATITUDE = "latitude",
  LONGITUDE = "longitude",
  SOURCE = "sourceCreated",
  COMPANIES = "accounts",
  COMPANIES_ADDRESSES = "accounts.address",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  TEAM = "team",
  TERRITORIES = "territories",
  VIEW_AS = "viewAs",
  ROUTES = "routes",
  COMPANIES_IMPORT_ID = "accounts.importId",
  NO_CHECK_IN = "noCheckIn",
  NO_VERIFIED_CHECK_IN = "noVerifiedCheckIn",
  PRIMARY_ACCOUNT = "primaryAccount",
}

const displayNames = defineMessages<PersonFieldName>({
  [PersonFieldName.ADDRESS]: {
    id: "person.field.address",
    defaultMessage: "Street",
    description: "Street field of person",
  },
  [PersonFieldName.CITY]: {
    id: "person.field.city",
    defaultMessage: "City",
    description: "City field of person",
  },
  [PersonFieldName.COLOR]: {
    id: "person.field.color",
    defaultMessage: "Color",
    description: "Color field of person",
  },
  [PersonFieldName.COMPANIES]: {
    id: "person.field.accounts",
    defaultMessage: "Companies",
    description: "Companies field of person",
  },
  [PersonFieldName.COMPANIES_ADDRESSES]: {
    id: "person.field.accounts.locations",
    defaultMessage: "Company Locations",
    description: "Companies Address field of person",
  },
  [PersonFieldName.COMPANIES_IMPORT_ID]: {
    id: "person.field.accounts.importId",
    defaultMessage: "Companies IDs",
    description: "CompanyId field of person",
  },
  [PersonFieldName.COUNTRY]: {
    id: "person.field.country",
    defaultMessage: "Country",
    description: "Country field of person",
  },
  [PersonFieldName.CREATED_AT]: {
    id: "person.field.createdAt",
    defaultMessage: "Date Created",
    description: "Created At field of person",
  },
  [PersonFieldName.CREATOR]: {
    id: "person.field.creator",
    defaultMessage: "Creator",
    description: "Creator field of person",
  },
  [PersonFieldName.CREATOR_EMAIL]: {
    id: "person.field.creatorEmail",
    defaultMessage: "Creator Email",
    description: "Creator Email field of person",
  },
  [PersonFieldName.CREATOR_NAME]: {
    id: "person.field.creatorName",
    defaultMessage: "Creator Name",
    description: "Creator Name field of person",
  },
  [PersonFieldName.CRM_LINK]: {
    id: "person.field.crmLink",
    defaultMessage: "CRM Link",
    description: "CRM Link field of person",
  },
  [PersonFieldName.DAYS_LEFT_IN_FREQUENCY]: {
    id: "person.field.daysLeftInFrequency",
    defaultMessage: "Days Left In Frequency",
    description: " Days Left In Frequency field of person",
  },
  [PersonFieldName.EMAIL]: {
    id: "person.field.email",
    defaultMessage: "Email",
    description: "Email field of person",
  },
  [PersonFieldName.FILES]: {
    id: "person.field.files",
    defaultMessage: "Files",
    description: "Files field of person",
  },
  [PersonFieldName.FIRST_NAME]: {
    id: "person.field.firstName",
    defaultMessage: "First Name",
    description: "First Name field of person",
  },
  [PersonFieldName.FREQUENCY_INTERVAL]: {
    id: "person.field.frequencyInterval",
    defaultMessage: "Frequency Interval",
    description: "Frequency Interval field of person",
  },
  [PersonFieldName.FREQUENCY_STATUS]: {
    id: "person.field.frequencyStatus",
    defaultMessage: "Frequency Status",
    description: "Frequency Status field of person",
  },
  [PersonFieldName.GEOPOINT]: {
    id: "person.field.geopoint",
    defaultMessage: "Location",
    description: "Location field of person",
  },
  [PersonFieldName.GROUPS]: {
    id: "person.field.groups",
    defaultMessage: "Group",
    description: "Groups field of person",
  },
  [PersonFieldName.LAST_ACTIVITY_DATE]: {
    id: "person.field.lastActivity.completedAt",
    defaultMessage: "Date of Last Done Activity",
    description: "Date of last done activity field of person",
  },
  [PersonFieldName.LAST_ACTIVITY_NAME]: {
    id: "person.field.lastActivity.name",
    defaultMessage: "Name of Last Done Activity",
    description: "Name of Last Done Activity field of person",
  },
  [PersonFieldName.LAST_ACTIVITY_TYPE]: {
    id: "person.field.lastActivity.activityType",
    defaultMessage: "Type of Last Done Activity",
    description: "Type of Last Done Activity field of person",
  },
  [PersonFieldName.LAST_NAME]: {
    id: "person.field.lastName",
    defaultMessage: "Last Name",
    description: "Last Name field of person",
  },
  [PersonFieldName.LATITUDE]: {
    id: "person.field.latitude",
    defaultMessage: "Latitude",
    description: "Latitude field of person",
  },
  [PersonFieldName.LONGITUDE]: {
    id: "person.field.longitude",
    defaultMessage: "Longitude",
    description: "Longitude field of person",
  },
  [PersonFieldName.NAME]: {
    id: "person.field.name",
    defaultMessage: "Name",
    description: "Name field of person",
  },
  [PersonFieldName.NEXT_ACTIVITY]: {
    id: "person.field.nextActivity",
    defaultMessage: "Date of Next Activity",
    description: "Next Activity field of person",
  },
  [PersonFieldName.NO_CHECK_IN]: {
    id: "person.field.noCheckIn",
    defaultMessage: "No Check-In for",
    description: "No CheckIns field of person",
  },
  [PersonFieldName.NO_CONTACT_IN]: {
    id: "person.field.noContactIn",
    defaultMessage: "No Contact In",
    description: "No Contact In field of person",
  },
  [PersonFieldName.NO_VERIFIED_CHECK_IN]: {
    id: "person.field.noVerifiedCheckIn",
    defaultMessage: "No Verified Check-In for",
    description: "No Verified CheckIns field of person",
  },
  [PersonFieldName.NOTES]: {
    id: "person.field.notes",
    defaultMessage: "Notes",
    description: "Notes field of person",
  },
  [PersonFieldName.OWNER_EMAIL]: {
    id: "person.field.ownerEmail",
    defaultMessage: "Owner Email",
    description: "Owner Email field of person",
  },
  [PersonFieldName.OWNER_NAME]: {
    id: "person.field.ownerName",
    defaultMessage: "Owner Name",
    description: "Owner Name field of person",
  },
  [PersonFieldName.PHONE]: {
    id: "person.field.phone",
    defaultMessage: "Phone",
    description: "Phone field of person",
  },
  [PersonFieldName.POSTAL_CODE]: {
    id: "person.field.postalCode",
    defaultMessage: "Postal Code",
    description: "Postal Code field of person",
  },
  [PersonFieldName.PRIMARY_ACCOUNT]: {
    id: "person.field.primaryAccount",
    defaultMessage: "Primary Company",
    description: "Primary Company field of person",
  },
  [PersonFieldName.REGION]: {
    id: "person.field.region",
    defaultMessage: "State/Region",
    description: "State/Region field of person",
  },
  [PersonFieldName.ROUTES]: {
    id: "person.field.routes",
    defaultMessage: "Routes",
    description: "Routes field of person",
  },
  [PersonFieldName.SOURCE]: {
    id: "person.field.source",
    defaultMessage: "Source",
    description: "Source field of person",
  },
  [PersonFieldName.TEAM]: {
    id: "person.field.team",
    defaultMessage: "Team",
    description: "Team field of person",
  },
  [PersonFieldName.TERRITORIES]: {
    id: "person.field.territories",
    defaultMessage: "Territories",
    description: "Territories field of person",
  },
  [PersonFieldName.UNIQUE_ID]: {
    id: "person.field.uniqueId",
    defaultMessage: "Unique ID",
    description: "Unique Id field of person",
  },
  [PersonFieldName.UPDATED_AT]: {
    id: "person.field.updatedAt",
    defaultMessage: "Date Last Modified",
    description: "Modified At field of person",
  },
  [PersonFieldName.USER]: {
    id: "person.field.user",
    defaultMessage: "Owner",
    description: "Owner field of person",
  },
  [PersonFieldName.VIEW_AS]: {
    id: "person.field.viewAs",
    defaultMessage: "Visible to",
    description: "View As field of person",
  },
});

const additionalDisplayNames = defineMessages({
  address: {
    id: "person.field.street.fieldManagementName",
    defaultMessage: "Address",
    description: "Field Management name for the street field of person",
  },
  companiesIds: {
    id: "person.field.accounts.importName",
    defaultMessage: "Companies - IDs",
    description: "Import name for the accounts field of person",
  },
  companyId: {
    id: "person.field.account.importName",
    defaultMessage: "Company - ID",
    description: "Import name for the account field of person",
  },
  name: {
    id: "person.field.firstName.fieldManagementName",
    defaultMessage: "Name (First and Last)",
    description: "Field Management name for the firstName field of person",
  },
});

const personFieldModel = new FieldModel(
  [
    new NameField({
      displayName: displayNames[PersonFieldName.NAME],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.ALWAYS_VISIBLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.RECORD_PREVIEW_VISIBLE_BY_DEFAULT,
        FieldFeature.MAP_PINNED_FIELD,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.HISTORY_FIELD,
      ],
      formProperties: {
        fullWidth: true,
      },
      name: PersonFieldName.NAME,
    }),
    new FirstNameField({
      displayName: displayNames[PersonFieldName.FIRST_NAME],
      fieldManagementDisplayName: additionalDisplayNames.name,
      name: PersonFieldName.FIRST_NAME,
    }),
    {
      customGridProperties: {
        cellClass: ["cursor-pointer"],
      },
      displayName: displayNames[PersonFieldName.LAST_NAME],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.FORM_RENDERED_WITH_OTHER_FIELD,
        FieldFeature.REQUIRED,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      formProperties: {
        fullWidth: true,
      },
      name: PersonFieldName.LAST_NAME,
      type: FieldType.STRING,
    },
    new SourceField({
      displayName: displayNames[PersonFieldName.SOURCE],
      name: PersonFieldName.SOURCE,
    }),
    new AddressField({
      displayName: displayNames[PersonFieldName.ADDRESS],
      displayOrder: 1,
      fieldManagementDisplayName: additionalDisplayNames.address,
      name: PersonFieldName.ADDRESS,
    }),
    {
      displayName: displayNames[PersonFieldName.ADDRESS],
      features: [
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.BULK_EDIT,
        FieldFeature.BULK_ADDRESS,
      ],
      name: "address_bulk",
      platformFilterName: PersonFieldName.ADDRESS,
      type: FieldType.STRING,
    },
    {
      displayName: displayNames[PersonFieldName.CITY],
      displayOrder: 2,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.ADDRESS,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.BULK_EDIT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      name: PersonFieldName.CITY,
      type: FieldType.STRING,
    },
    new RegionField({
      displayName: displayNames[PersonFieldName.REGION],
      displayOrder: 3,
      name: PersonFieldName.REGION,
    }),
    {
      displayName: displayNames[PersonFieldName.POSTAL_CODE],
      displayOrder: 5,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.BULK_EDIT,
        FieldFeature.ADDRESS,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      name: PersonFieldName.POSTAL_CODE,
      type: FieldType.STRING,
    },
    new CountryField({
      displayName: displayNames[PersonFieldName.COUNTRY],
      displayOrder: 4,
      name: PersonFieldName.COUNTRY,
      platformFilterName: "countryCode",
    }),
    {
      customGridProperties: {
        cellRenderer: "phoneCellRenderer",
      },
      displayName: displayNames[PersonFieldName.PHONE],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.BULK_EDIT,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      name: PersonFieldName.PHONE,
      type: FieldType.STRING,
    },
    {
      customGridProperties: {
        cellRenderer: "emailCellRenderer",
      },
      displayName: displayNames[PersonFieldName.EMAIL],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.BULK_EDIT,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      name: PersonFieldName.EMAIL,
      type: FieldType.STRING,
    },
    {
      displayName: displayNames[PersonFieldName.FILES],
      features: [
        FieldFeature.FILE_FIELD,
        // not visible anywhere, except in forms
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_MAP_VIEW,
      ],
      formProperties: {
        fullWidth: true,
      },
      name: PersonFieldName.FILES,
      type: FieldType.LIST,
    },
    {
      customGridProperties: {
        cellRenderer: "crmLinkCellRenderer",
      },
      displayName: displayNames[PersonFieldName.CRM_LINK],
      features: [
        FieldFeature.EXTERNAL_URL_FIELD,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.NON_IMPORT,
        FieldFeature.SORTABLE,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.DETAILS,
      ],
      name: PersonFieldName.CRM_LINK,
      type: FieldType.STRING,
    },
    new GroupsField({
      displayName: displayNames[PersonFieldName.GROUPS],
      name: PersonFieldName.GROUPS,
    }),
    new FrequencyIntervalField({
      displayName: displayNames[PersonFieldName.FREQUENCY_INTERVAL],
      features: [
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_IMPORT,
        FieldFeature.ENGAGEMENT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.BULK_EDIT,
        FieldFeature.FREQUENCY_INTERVAL,
        FieldFeature.FILTERABLE_ON_MAP,
      ],
      name: PersonFieldName.FREQUENCY_INTERVAL,
    }),
    new ColorField({
      displayName: displayNames[PersonFieldName.COLOR],
      name: PersonFieldName.COLOR,
    }),
    new UserField({
      displayName: displayNames[PersonFieldName.CREATOR],
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_IMPORT,
        FieldFeature.DATA_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: PersonFieldName.CREATOR,
      platformFilterName: "createdBy",
      platformName: "createdBy",
    }),
    new UserField({
      displayName: displayNames[PersonFieldName.CREATOR_NAME],
      exportColumnName: "creator",
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      filterName: "creator",
      name: PersonFieldName.CREATOR_NAME,
      platformFilterName: "createdBy",
      valueGetter: (entity: unknown) => (entity as Person)?.creator,
    }),
    {
      displayName: displayNames[PersonFieldName.CREATOR_EMAIL],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: PersonFieldName.CREATOR_EMAIL,
      type: FieldType.STRING,
    },
    new UserField({
      displayName: displayNames[PersonFieldName.USER],
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.OWNER_FIELD,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.BULK_EDIT,
        FieldFeature.DATA_VIEW,
        FieldFeature.HISTORY_FIELD,
      ],
      importProperties: {
        required: false,
      },
      name: PersonFieldName.USER,
      platformFilterName: "userId",
      platformName: "userId",
    }),
    new UserField({
      displayName: displayNames[PersonFieldName.OWNER_NAME],
      exportColumnName: "user",
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.OWNER_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      filterName: "user",
      name: PersonFieldName.OWNER_NAME,
      platformFilterName: "userId",
      valueGetter: (entity: unknown) => (entity as Person)?.user,
    }),
    {
      displayName: displayNames[PersonFieldName.OWNER_EMAIL],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: PersonFieldName.OWNER_EMAIL,
      type: FieldType.STRING,
    },
    new DateField({
      customGridProperties: {
        cellRenderer: "activityAnnotationLinkCellRenderer",
      },
      displayName: displayNames[PersonFieldName.NEXT_ACTIVITY],
      displayOrder: 11,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.ENGAGEMENT,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: PersonFieldName.NEXT_ACTIVITY,
    }),
    new DateField({
      customGridProperties: {
        cellRenderer: "activityAnnotationLinkCellRenderer",
      },
      displayName: displayNames[PersonFieldName.LAST_ACTIVITY_DATE],
      displayOrder: 12,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.ENGAGEMENT,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: PersonFieldName.LAST_ACTIVITY_DATE,
    }),
    {
      displayName: displayNames[PersonFieldName.LAST_ACTIVITY_NAME],
      features: [
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_MAP_VIEW,
      ],
      name: PersonFieldName.LAST_ACTIVITY_NAME,
      type: FieldType.STRING,
    },
    {
      displayName: displayNames[PersonFieldName.LAST_ACTIVITY_TYPE],
      features: [
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_MAP_VIEW,
      ],
      name: PersonFieldName.LAST_ACTIVITY_TYPE,
      type: FieldType.STRING,
    },
    new FrequencyStatusField({
      displayName: displayNames[PersonFieldName.FREQUENCY_STATUS],
      displayOrder: 13,
      name: PersonFieldName.FREQUENCY_STATUS,
    }),
    new NoContactField({
      displayName: displayNames[PersonFieldName.NO_CONTACT_IN],
      displayOrder: 14,
      name: PersonFieldName.NO_CONTACT_IN,
    }),
    new NotesField({
      displayName: displayNames[PersonFieldName.NOTES],
      name: PersonFieldName.NOTES,
    }),
    new UniqueIdField({
      displayName: displayNames[PersonFieldName.UNIQUE_ID],
      name: PersonFieldName.UNIQUE_ID,
    }),
    new MapLocationField({
      displayName: displayNames[PersonFieldName.GEOPOINT],
      displayOrder: 6,
      name: PersonFieldName.GEOPOINT,
    }),
    {
      displayName: displayNames[PersonFieldName.LATITUDE],
      features: [
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: PersonFieldName.LATITUDE,
      type: FieldType.NUMBER,
    },
    {
      displayName: displayNames[PersonFieldName.LONGITUDE],
      features: [
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: PersonFieldName.LONGITUDE,
      type: FieldType.NUMBER,
    },
    {
      customFilterConfig: {
        defaultInstance: "entity",
        filterInstances: {
          entity: getEntityFilter(EntityType.COMPANY, [
            FilterOperator.IN_ANY,
            FilterOperator.IN_ALL,
            FilterOperator.NONE_OF,
          ]),
        },
        operators: [FilterOperator.IN_ANY, FilterOperator.IN_ALL, FilterOperator.NONE_OF],
      },
      customGridProperties: {
        cellRenderer: "companiesCellRenderer",
      },
      displayName: displayNames[PersonFieldName.COMPANIES],
      features: [
        FieldFeature.BULK_EDIT,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.FILTERABLE,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.RELATIONSHIPS,
        FieldFeature.SUPPORTS_SET_FILTER,
      ],
      filterType: FieldType.ID,
      importProperties: {
        bottomPosition: true,
        displayName: additionalDisplayNames.companiesIds,
        name: "accounts",
        required: false,
      },
      name: PersonFieldName.COMPANIES,
      platformFilterName: "accounts.id",
      platformName: "accountId",
      type: FieldType.LIST,
      valueFormatter: listFormatter("name", "; "),
    },
    new NestedAddressField(
      {
        displayName: displayNames[PersonFieldName.COMPANIES_ADDRESSES],
        name: PersonFieldName.COMPANIES_ADDRESSES,
      },
      ["accounts"]
    ),
    {
      customGridProperties: {
        cellRenderer: "primaryCompanyCellRenderer",
      },
      displayName: displayNames[PersonFieldName.PRIMARY_ACCOUNT],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: PersonFieldName.PRIMARY_ACCOUNT,
      type: FieldType.OBJECT,
    },
    new DateTimeField({
      displayName: displayNames[PersonFieldName.CREATED_AT],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
      ],
      name: PersonFieldName.CREATED_AT,
    }),
    new DateTimeField({
      displayName: displayNames[PersonFieldName.UPDATED_AT],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
      ],
      name: PersonFieldName.UPDATED_AT,
    }),
    new TeamField({
      displayName: displayNames[PersonFieldName.TEAM],
      name: PersonFieldName.TEAM,
      platformFilterName: "teamId",
    }),
    new TerritoryField({
      displayName: displayNames[PersonFieldName.TERRITORIES],
      name: PersonFieldName.TERRITORIES,
    }),
    // filter-only fields:
    new ViewAsField({
      displayName: displayNames[PersonFieldName.VIEW_AS],
      name: PersonFieldName.VIEW_AS,
    }),
    new RoutesField({
      displayName: displayNames[PersonFieldName.ROUTES],
      name: PersonFieldName.ROUTES,
    }),
    {
      displayName: displayNames[PersonFieldName.COMPANIES_IMPORT_ID],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: PersonFieldName.COMPANIES_IMPORT_ID,
      type: FieldType.STRING,
      valueGetter: (entity: unknown) => {
        return (entity as Person).accounts.map(({ importId }) => importId).join("; ");
      },
    },
    new FrequencyDaysLeft({
      displayName: displayNames[PersonFieldName.DAYS_LEFT_IN_FREQUENCY],
      name: PersonFieldName.DAYS_LEFT_IN_FREQUENCY,
    }),
    new NoCheckInField({
      displayName: displayNames[PersonFieldName.NO_CHECK_IN],
      name: PersonFieldName.NO_CHECK_IN,
    }),
    new NoVerifiedCheckInField({
      displayName: displayNames[PersonFieldName.NO_VERIFIED_CHECK_IN],
      name: PersonFieldName.NO_VERIFIED_CHECK_IN,
    }),
  ].map(createField)
);

export default personFieldModel;
