import React from "react";

import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons/faSpinnerThird";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import notification from "antd/es/notification";
import uniq from "lodash-es/uniq";
import { defineMessage, defineMessages } from "react-intl";
import { put, select, takeLatest } from "redux-saga/effects";

import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import {
  AnyEntityId,
  EntitiesSupportingRoutes,
  EntityType,
  Route,
} from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import i18nService from "@app/config/I18nService";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganization } from "@app/store/iam";
import { getEntityTypeDisplayName } from "@app/util/ui";

import { addToRoute, createRoute, filterRoutes, initialize } from "./actions";
import styles from "./saga.module.scss";
import { getAutoAssignOnCreate, getEntities, getSelectedRouteId } from "./selectors";

const routeAddedSuccessMessages = defineMessages({
  existingRoute: {
    id: "addToRouteModal.existingRoute.successMessage",
    defaultMessage: "{entityName} successfully added to Route",
    description: "Route is updated successfully in the Add to Route modal",
  },
  newRoute: {
    id: "addToRouteModal.newRoute.successMessage",
    defaultMessage: "{entityName} successfully added to newly created Route",
    description: "Route is updated successfully in the Add to Route modal",
  },
});

const validationErrorMessages = defineMessages({
  v0281: {
    id: "routes.validationError.v0281",
    defaultMessage:
      "No route could be found between the starting point, stops and the ending point.",
    description: "Route validation error when no route could be found",
  },
});

const failedGeocodeStartAddressMessage = defineMessage({
  id: "createRouteModal.error.failedGeocodeStart",
  defaultMessage: "Failed to find starting location",
  description: "Failed to find starting location in Create New Route modal",
});

const failedGeocodeEndAddressMessage = defineMessage({
  id: "createRouteModal.error.failedGeocodeEnd",
  defaultMessage: "Failed to find ending location",
  description: "Failed to find ending location in Create New Route modal",
});

export function* onInitialize({ payload }: ReturnType<typeof initialize.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const routes: ListResponse<Route> = yield callApi(
      payload.entityType === EntityType.COMPANY ? "fetchCompanyRoutes" : "fetchPeopleRoutes",
      org.id,
      { $filters: { includeAccessStatus: true }, $limit: 100, $order: ["name"] }
    );

    // TODO: think about filtering out routes which already include payload.entities
    // Now it's not possible since routeAccount (or routeContacts) field is not returned
    // by default. And forcing it to be returned may lead to unnecessary platform load.

    yield put(initialize.success({ routes: routes.data }));
  } catch (error) {
    yield put(initialize.failure());
    yield put(handleError({ error }));
  }
}

export function* onAddToRoute({ payload }: ReturnType<typeof addToRoute.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const selectedRouteId: Route["id"] | undefined = payload.routeId
      ? payload.routeId
      : yield select(getSelectedRouteId);
    if (!selectedRouteId) {
      return;
    }

    const entityIds = payload.entities.map(({ id }) => id);

    // try to build a route with new entities

    const route: Route = yield callApi(
      payload.entityType === EntityType.COMPANY ? "fetchCompanyRoute" : "fetchPeopleRoute",
      org.id,
      selectedRouteId,
      true,
      {
        includeAccessStatus: true,
      }
    );

    const previewPayload = {
      endGeoPoint: route.routeDetail.endGeoPoint,
      routeAccounts:
        payload.entityType === EntityType.COMPANY
          ? // using uniq to preserve stops order
            uniq([...(route.routeAccounts ?? []).map(({ id }) => id), ...entityIds])
          : undefined,
      routeContacts:
        payload.entityType === EntityType.PERSON
          ? // using uniq to preserve stops order
            uniq([...(route.routeContacts ?? []).map(({ id }) => id), ...entityIds])
          : undefined,
      routeType: route.routeDetail.type,
      startGeoPoint: route.routeDetail.startGeoPoint,
    };

    yield callApi("buildRoute", org.id, payload.entityType, previewPayload);

    // now update route if buildRoute hasn't fail
    yield callApi(
      "updateRouteStops",
      org.id,
      payload.entityType,
      selectedRouteId,
      entityIds,
      route.routeDetail.allottedTime
    );

    if (payload.showNotification !== false) {
      const intl = i18nService.getIntl();
      if (intl) {
        notification.success({
          message: intl.formatMessage(routeAddedSuccessMessages.existingRoute, {
            count: entityIds.length,
            entityName: getEntityTypeDisplayName(intl, payload.entityType, {
              lowercase: false,
              plural: entityIds.length > 1,
            }),
          }),
        });
      }
    }

    payload.callback(true);
    yield put(addToRoute.success());
  } catch (error) {
    yield put(addToRoute.failure());
    yield put(handleError({ error, messageByErrorCodeMap: validationErrorMessages }));
  }
}

export function* onCreateRoute({ payload }: ReturnType<typeof createRoute.request>) {
  const key = `createRouteNotification_${payload.route.name}`;
  try {
    const org: Organization = yield select(getOrganization);
    const intl = i18nService.getIntl();
    const { callback, route } = payload;

    let startPoint: GeocodeResult;
    try {
      startPoint = yield callApi("geocodeAddress", org.id, {
        address: route.routeDetail.startAddress,
      });
    } catch {
      notification.error({ message: intl?.formatMessage(failedGeocodeStartAddressMessage) });
      yield put(createRoute.failure());
      return;
    }

    let endPoint: GeocodeResult | undefined;
    if (route.routeDetail.endAddress) {
      try {
        endPoint = yield callApi("geocodeAddress", org.id, {
          address: route.routeDetail.endAddress,
        });
      } catch {
        notification.error({ message: intl?.formatMessage(failedGeocodeEndAddressMessage) });
        yield put(createRoute.failure());
        return;
      }
    }
    const autoAssignOnCreate: boolean = yield select(getAutoAssignOnCreate);
    const entities: EntitiesSupportingRoutes[] = yield select(getEntities);
    const entityIds: Array<AnyEntityId> = entities.map(({ id }) => id);

    if (autoAssignOnCreate) {
      // try to build a route with new entities
      const previewPayload = {
        endGeoPoint: endPoint?.geoPoint ? endPoint?.geoPoint : undefined,
        routeType: route.routeDetail.type,
        startGeoPoint: startPoint.geoPoint ? startPoint.geoPoint : undefined,
        ...route.routeDetail,
        routeAccounts: route.entityType === EntityType.COMPANY ? entityIds : undefined,
        routeContacts: route.entityType === EntityType.PERSON ? entityIds : undefined,
      };

      //Info message to show user that we are adding the record to route after creating the route
      notification.info({
        icon: <FontAwesomeIcon className={styles.notificationLoader} icon={faSpinnerThird} spin />,
        key,
        message: intl?.formatMessage({
          id: "createRouteModal.buildingRoute",
          defaultMessage: "Building route...",
          description: "message for build route when creating new route",
        }),
      });

      yield callApi("buildRoute", org.id, route.entityType, previewPayload);
    }

    //Create Route
    const createdRoute: Route = yield callApi("createRoute", org.id, route.entityType, {
      name: route.name,
      routeDetail: {
        ...route.routeDetail,
        endGeoPoint: endPoint?.geoPoint ? endPoint?.geoPoint : undefined,
        startGeoPoint: startPoint.geoPoint,
      },
    } as Partial<Route>);

    if (autoAssignOnCreate) {
      // now update route if all is success buildRoute hasn't fail
      yield callApi(
        "updateRouteStops",
        org.id,
        route.entityType,
        createdRoute.id,
        entityIds,
        route.routeDetail.allottedTime
      );

      notification.success({
        key,
        message: intl?.formatMessage(routeAddedSuccessMessages.newRoute, {
          entityName: getEntityTypeDisplayName(intl, route.entityType, {
            lowercase: false,
            plural: entityIds.length > 1,
          }),
        }),
      });
    }

    yield put(createRoute.success(createdRoute));
    callback?.(createdRoute);
  } catch (error) {
    notification.close(key);
    yield put(createRoute.failure());
    yield put(handleError({ error }));
  }
}

export function* onFilterRoutes({ payload }: ReturnType<typeof filterRoutes.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const routes: ListResponse<Route> = yield callApi(
      payload.entityType === EntityType.COMPANY ? "fetchCompanyRoutes" : "fetchPeopleRoutes",
      org.id,
      {
        $filters: {
          includeAccessStatus: true,
          name: payload.query ? { $in: payload.query } : undefined,
        },
        $limit: 100,
        $order: ["name"],
      }
    );
    yield put(filterRoutes.success(routes.data));
  } catch (error) {
    yield put(filterRoutes.failure());
    yield put(handleError({ error }));
  }
}

export function* addToRouteModalSaga() {
  yield takeLatest(initialize.request, onInitialize);
  yield takeLatest(addToRoute.request, onAddToRoute);
  yield takeLatest(createRoute.request, onCreateRoute);
  yield takeLatest(filterRoutes.request, onFilterRoutes);
}
