import { defineMessages, IntlShape } from "react-intl";

enum FilterType {
  ALL = "all",
  DOWNLOADED = "downloaded",
  FAVORITE = "favorite",
}

const messages = defineMessages({
  [FilterType.ALL]: {
    id: "reports.exports.filterType.all",
    defaultMessage: "All",
    description: "Name of the All filter or the Reports page",
  },
  [FilterType.DOWNLOADED]: {
    id: "reports.exports.filterType.downloaded",
    defaultMessage: "Downloaded",
    description: "Name of the Downloaded filter on the Reports page",
  },
  [FilterType.FAVORITE]: {
    id: "reports.exports.filterType.favorite",
    defaultMessage: "Favorite",
    description: "Name of the Favorite filter on the Reports page",
  },
});

export const getFilterTypeDisplayName = (intl: IntlShape, filterType: FilterType): string =>
  intl.formatMessage(messages[filterType]);

export default FilterType;
