import { defineMessages, IntlShape } from "react-intl";

import SchemaFieldType, {
  SchemaFieldTypeFilter,
  SchemaFieldTypeValues,
} from "@mapmycustomers/shared/enum/SchemaFieldType";

export const schemaFieldTypeFilterNames = defineMessages<SchemaFieldTypeValues>({
  [SchemaFieldType.AUXILIARY]: {
    id: "schemaFieldTypeFilter.auxiliary",
    defaultMessage: "Auxiliary",
    description: "Schema field type filter - Auxiliary",
  },
  [SchemaFieldType.CUSTOM]: {
    id: "schemaFieldTypeFilter.custom",
    defaultMessage: "Custom",
    description: "Schema field type filter - Custom",
  },
  [SchemaFieldType.DIVIDER]: {
    id: "schemaFieldTypeFilter.divider",
    defaultMessage: "Divider",
    description: "Schema field type filter - Divider",
  },
  [SchemaFieldType.FILE]: {
    id: "entityFieldTypeFilter.file",
    defaultMessage: "Files",
    description: "Schema field type filter - File",
  },
  [SchemaFieldType.GROUP]: {
    id: "schemaFieldTypeFilter.group",
    defaultMessage: "Groups",
    description: "Schema field type filter - Group",
  },
  [SchemaFieldType.NOTE]: {
    id: "schemaFieldTypeFilter.note",
    defaultMessage: "Notes",
    description: "Schema field type filter - Note",
  },
  [SchemaFieldType.STANDARD]: {
    id: "schemaFieldTypeFilter.standard",
    defaultMessage: "Standard",
    description: "Schema field type filter - Standard",
  },
  [SchemaFieldTypeFilter.ALL]: {
    id: "schemaFieldTypeFilter.all",
    defaultMessage: "All Field Types",
    description: "Entity field type filter - All Field Types",
  },
  [SchemaFieldTypeFilter.RELATIONSHIP]: {
    id: "schemaFieldTypeFilter.relationship",
    defaultMessage: "Relationship",
    description: "Schema field type filter - Relationship",
  },
  [SchemaFieldTypeFilter.SYSTEM]: {
    id: "schemaFieldTypeFilter.system",
    defaultMessage: "System",
    description: "Schema field type filter - System",
  },
});

const getSchemaFieldTypeFilterName = (intl: IntlShape, filter: SchemaFieldTypeValues) =>
  intl.formatMessage(schemaFieldTypeFilterNames[filter]);

export default getSchemaFieldTypeFilterName;
