import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import { FilterCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import {
  Condition,
  PlatformFilterCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

import { formatRawDate } from "@app/util/formatters";
import { parseApiDateWithUtcTz } from "@app/util/parsers";
import { isCombinedCondition, isCombinedPlatformCondition } from "@app/util/viewModel/assert";
import { getRegularFieldConditionValue } from "@app/util/viewModel/convertFromPlatformFilterModel";
import {
  filterCombineOperatorToPlatformCombineOperator,
  getRegularFieldPlatformConditionValue,
} from "@app/util/viewModel/convertToPlatformFilterModel";

import DateField from "./DateField";

class ClosingDateField extends DateField {
  get isEssential() {
    return false;
  }

  convertToPlatformCondition(filterCondition: FilterCondition): PlatformFilterCondition {
    if (isCombinedCondition(filterCondition)) {
      const operator = filterCombineOperatorToPlatformCombineOperator[filterCondition.operator];
      return {
        [operator]: filterCondition.conditions.map((simpleCondition) => ({
          [this.platformFilterName]: getRegularFieldPlatformConditionValue(this, simpleCondition),
        })),
      };
    } else {
      if (filterCondition.operator === FilterOperator.ON) {
        const date = formatRawDate(filterCondition.value, "yyyy-MM-dd");
        return {
          [this.platformFilterName]: { $dateOnly: true, $eq: date },
        };
      }

      return {
        [this.platformFilterName]: getRegularFieldPlatformConditionValue(this, filterCondition),
      };
    }
  }

  convertFromPlatformCondition(
    filterCondition: PlatformFilterCondition
  ): FilterCondition | undefined {
    if (isCombinedPlatformCondition(filterCondition)) {
      return super.convertFromPlatformCondition(filterCondition);
    } else {
      if (this.platformFilterName in filterCondition) {
        const condition = filterCondition[this._platformFilterName] as Condition;
        if (PlatformFilterOperator.EQUALS in condition) {
          const value = condition[PlatformFilterOperator.EQUALS] as string;
          return {
            operator: FilterOperator.ON,
            value: parseApiDateWithUtcTz(`${value}T00:00:00Z`).toISOString(),
          };
        }
        return getRegularFieldConditionValue(this, filterCondition);
      }
      return getRegularFieldConditionValue(this, { [this.platformFilterName]: filterCondition });
    }
  }
}

export default ClosingDateField;
