enum SchemaFieldType {
  CUSTOM = "custom-field",
  DIVIDER = "divider",
  FILE = "file",
  GROUP = "group",
  NOTE = "note",
  STANDARD = "standard",
  AUXILIARY = "auxiliary",
}

export enum SchemaFieldTypeFilter {
  ALL = "all",
  SYSTEM = "system",
  RELATIONSHIP = "relationship",
}

export type SchemaFieldTypeValues = SchemaFieldType | SchemaFieldTypeFilter;

export const schemaFieldTypeFilterOptions = [
  SchemaFieldTypeFilter.ALL,
  SchemaFieldTypeFilter.SYSTEM,
  SchemaFieldTypeFilter.RELATIONSHIP,
  SchemaFieldType.CUSTOM,
];

export default SchemaFieldType;
