import { createReducer } from "typesafe-actions";

import AssociationsState from "@app/store/associations/AssociationsState";
import { DEFAULT_ASSOCIATIONS_STATE } from "@app/store/associations/const";
import FileListItem from "@app/types/FileListItem";

import {
  Actions,
  changeAssociatedEntities,
  clearAllUploadedDealFiles,
  createDeal,
  hideGlobalCreateDealModal,
  initialize,
  relateUnrelateEntities,
  removeDealFile,
  showGlobalCreateDealModal,
  uploadDealFiles,
} from "./actions";

export interface CreateDealModalState {
  associations: AssociationsState;
  createError: Record<string, string> | undefined;
  createLoading: boolean;
  entityRelating: boolean;
  entityRelatingSuccess: boolean;
  fileGroupId: string;
  filesAdded: FileListItem[];
  filesDeleting: boolean;
  filesUploading: boolean;
  initializeLoading: boolean;
  isGlobalAddDeal?: boolean;
  isWarningDismissed: boolean;
  visible: boolean;
}

const initialState: CreateDealModalState = {
  associations: DEFAULT_ASSOCIATIONS_STATE,
  createError: undefined,
  createLoading: false,
  entityRelating: false,
  entityRelatingSuccess: false,
  fileGroupId: "",
  filesAdded: [],
  filesDeleting: false,
  filesUploading: false,
  initializeLoading: false,
  isWarningDismissed: false,
  visible: false,
};

const createDealModal = createReducer<CreateDealModalState, Actions>(initialState)
  .handleAction(showGlobalCreateDealModal, (state, action) => ({
    ...state,
    isGlobalAddDeal: action.payload?.showPostCreatePrompt,
    visible: true,
  }))
  .handleAction(hideGlobalCreateDealModal, (state) => ({
    ...state,
    isGlobalAddDeal: false,
    visible: false,
  }))
  .handleAction(initialize.request, (state, { payload }) => ({
    ...state,
    associations: {
      ...state.associations,
      associatedCompany: payload.fixedCompany,
      associatedPerson: payload.fixedPerson,
    },
    entityRelatingSuccess: false,
    filesAdded: [],
    initializeError: undefined,
    initializeLoading: true,
  }))
  .handleAction(initialize.success, (state, { payload }) => ({
    ...state,
    associations: { ...state.associations, ...payload.associationsState },
    fileGroupId: payload.fileGroupId,
    initializeLoading: false,
  }))
  .handleAction(initialize.failure, (state) => ({
    ...state,
    initializeLoading: false,
  }))
  .handleAction(createDeal.request, (state) => ({
    ...state,
    createError: undefined,
    createLoading: true,
  }))
  .handleAction(createDeal.success, (state, action) => ({
    ...state,
    ...action.payload,
    createLoading: false,
  }))
  .handleAction(createDeal.failure, (state, action) => ({
    ...state,
    createError: action.payload,
    createLoading: false,
  }))
  .handleAction(changeAssociatedEntities.request, (state, { payload }) => ({
    ...state,
    associations: {
      ...state.associations,
      associatedCompany:
        "company" in payload ? payload.company : state.associations.associatedCompany,
      associatedPerson: "person" in payload ? payload.person : state.associations.associatedPerson,
      availableLoading: true,
    },
    entityRelatingSuccess: false,
  }))
  .handleAction(changeAssociatedEntities.success, (state, { payload }) => ({
    ...state,
    associations: { ...state.associations, ...payload, availableLoading: false },
  }))
  .handleAction(changeAssociatedEntities.failure, (state) => ({
    ...state,
    associations: { ...state.associations, availableLoading: false },
  }))
  .handleAction(uploadDealFiles.request, (state) => ({
    ...state,
    filesUploading: true,
  }))
  .handleAction(uploadDealFiles.success, (state, action) => ({
    ...state,
    filesAdded: [...state.filesAdded, ...action.payload],
    filesUploading: false,
  }))
  .handleAction(uploadDealFiles.failure, (state) => ({
    ...state,
    filesUploading: false,
  }))
  .handleAction(removeDealFile.request, (state) => ({
    ...state,
    filesDeleting: true,
  }))
  .handleAction(removeDealFile.success, (state, action) => ({
    ...state,
    //Removing the deleted file from store
    filesAdded: state.filesAdded.filter((file) => file.uploadedFile?.id !== action.payload),
    filesDeleting: false,
  }))
  .handleAction(removeDealFile.failure, (state) => ({
    ...state,
    filesDeleting: false,
  }))
  .handleAction(clearAllUploadedDealFiles, (state) => ({
    ...state,
    filesAdded: [],
    filesUploading: false,
  }))
  .handleAction(relateUnrelateEntities.request, (state) => ({
    ...state,
    entityRelating: true,
  }))
  .handleAction(
    relateUnrelateEntities.success,
    (state, { payload: { associationsState, person, unrelate } }) => ({
      ...state,
      associations: {
        ...state.associations,
        ...associationsState,
        associatedPerson: person ?? state.associations.associatedPerson,
      },
      entityRelating: false,
      entityRelatingSuccess: unrelate ? false : true,
    })
  )
  .handleAction(relateUnrelateEntities.failure, (state) => ({
    ...state,
    entityRelating: false,
    entityRelatingSuccess: false,
  }));

export * from "./selectors";
export type CreateDealModalActions = Actions;

export default createDealModal;
