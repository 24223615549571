import { defineMessages } from "react-intl";

import { EntityType } from "@mapmycustomers/shared";
import ActivityStatus from "@mapmycustomers/shared/enum/activity/ActivityStatus";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { Activity } from "@mapmycustomers/shared/types/entity";
import { IHumanReadableFilterConfig } from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";

import i18nService from "@app/config/I18nService";
import getActivityStatus from "@app/util/activity/getActivityStatus";
import CheckInLocationField from "@app/util/fieldModel/impl/CheckInLocationField";
import defaultFilters from "@app/util/filters/defaultFilters";
import DurationFilter, { DURATION_FILTER_OPERATORS } from "@app/util/filters/DurationFilter";
import DurationRangeFilter from "@app/util/filters/DurationRangeFilter";
import EmptyFilter from "@app/util/filters/EmptyFilter";
import getEntityFilter, { ENTITY_FILTER_OPERATORS } from "@app/util/filters/EntityFilter";
import { parseApiDate } from "@app/util/parsers";
import { getActivityStatusDisplayName } from "@app/util/ui";

import ActivityNameField from "./impl/ActivityNameField";
import ActivityNoteField from "./impl/ActivityNoteField";
import ActivityTypeField from "./impl/ActivityTypeField";
import DateField from "./impl/DateField";
import DateTimeField from "./impl/DateTimeField";
import FieldModel from "./impl/FieldModel";
import { createField } from "./impl/fieldModelUtil";
import {
  dateFormatter,
  distanceFormatter,
  getUserTeams,
  teamsFormatter,
  timeFormatter,
} from "./impl/fieldUtil";
import SourceField from "./impl/SourceField";
import TeamField from "./impl/TeamField";
import UniqueIdField from "./impl/UniqueIdField";
import UserField from "./impl/UserField";
import addressFieldsMessages from "./util/addressFieldsMessages";

export enum ActivityFieldName {
  NAME = "name",
  COMPANY = "account",
  PERSON = "contact",
  ACCOUNT_ADDRESS = "account.address",
  CONTACT_ADDRESS = "contact.address",
  DEAL = "deal",
  ACTIVITY_TYPE = "activityType",
  CREATOR = "creator",
  CREATOR_NAME = "creatorName",
  CREATOR_EMAIL = "creator.username",
  USER = "user",
  OWNER_NAME = "ownerName",
  OWNER_EMAIL = "user.username",
  OWNER_TEAM_NAME = "userTeamName",
  COMPLETED = "completed",
  VISIBILITY = "visibility",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  START_AT = "startAt",
  START_DATE = "startDate",
  START_TIME = "startTime",
  END_AT = "endAt",
  END_DATE = "endDate",
  END_TIME = "endTime",
  SOURCE = "sourceCreated",
  COMPLETED_AT = "completedAt",
  COMPLETED_TIME = "completedTime",
  COMPLETED_DATE = "completedDate",
  STATUS = "status",
  NOTE = "note",
  ASSIGNEE = "assignee",
  ASSIGNEE_NAME = "assignee.fullName",
  ASSIGNEE_EMAIL = "assignee.username",
  ASSIGNEE_TEAM_NAME = "assigneeTeamName",
  DURATION = "duration",
  UNIQUE_ID = "importId",
  CRM_LINK = "crmLink",
  FREQUENCY = "recurInterval",
  GEOPOINT_CHECK_IN = "geoPointCheckin",
  CHECK_IN_DISTANCE = "distanceBetweenCheckInAndEntity",
  RELIABILITY = "reliability",
  QUICK_FILTER = "quickFilter",
  TEAM = "teams",
  FILES = "files",
  ACCOUNT_IMPORT_ID = "account.importId",
  CONTACT_IMPORT_ID = "contact.importId",
  DEAL_IMPORT_ID = "deal.importId",
  PRIMARY_ASSOCIATION = "primaryAssociation",
}

export const displayNames = defineMessages<ActivityFieldName>({
  [ActivityFieldName.ACCOUNT_ADDRESS]: {
    id: "activity.field.companyAddress",
    defaultMessage: "Company Address",
    description: "Company address field of activity",
  },
  [ActivityFieldName.ACCOUNT_IMPORT_ID]: {
    id: "activity.field.account.importId",
    defaultMessage: "Company ID",
    description: "CompanyId field of activity",
  },
  [ActivityFieldName.ACTIVITY_TYPE]: {
    id: "activity.field.activityType",
    defaultMessage: "Activity Type",
    description: "Activity type field of activity",
  },
  [ActivityFieldName.ASSIGNEE]: {
    id: "activity.field.assignee",
    defaultMessage: "Assignee",
    description: "Assignee field of activity",
  },
  [ActivityFieldName.ASSIGNEE_EMAIL]: {
    id: "activity.field.assigneeEmail",
    defaultMessage: "Assignee Email",
    description: "Assignee email field of activity",
  },
  [ActivityFieldName.ASSIGNEE_NAME]: {
    id: "activity.field.assigneeName",
    defaultMessage: "Assignee Name",
    description: "Assignee name field of activity",
  },
  [ActivityFieldName.ASSIGNEE_TEAM_NAME]: {
    id: "activity.field.assigneeTeamName",
    defaultMessage: "Assignee Team Name",
    description: "Assignee team name field of activity",
  },
  [ActivityFieldName.CHECK_IN_DISTANCE]: {
    id: "activity.field.checkInDistanceDelta",
    defaultMessage: "Check-In Distance Delta",
    description: "Check in distance delta field of activity",
  },
  [ActivityFieldName.COMPANY]: {
    id: "activity.field.account",
    defaultMessage: "Company",
    description: "Account field of activity",
  },
  [ActivityFieldName.COMPLETED]: {
    id: "activity.field.completed",
    defaultMessage: "Done",
    description: "Done field of activity",
  },
  [ActivityFieldName.COMPLETED_AT]: {
    id: "activity.field.completedAt",
    defaultMessage: "Date Done",
    description: "Done at field of activity",
  },
  [ActivityFieldName.COMPLETED_DATE]: {
    id: "activity.field.completedDate",
    defaultMessage: "Date Done",
    description: "Completed date field of activity",
  },
  [ActivityFieldName.COMPLETED_TIME]: {
    id: "activity.field.completedTime",
    defaultMessage: "Time Done",
    description: "Done time field of activity",
  },
  [ActivityFieldName.CONTACT_ADDRESS]: {
    id: "activity.field.personAddress",
    defaultMessage: "Person Address",
    description: "Person address field of activity",
  },
  [ActivityFieldName.CONTACT_IMPORT_ID]: {
    id: "activity.field.person.importId",
    defaultMessage: "Person ID",
    description: "PersonId field of activity",
  },
  [ActivityFieldName.CREATED_AT]: {
    id: "activity.field.createdAt",
    defaultMessage: "Date Created",
    description: "Created at field of activity",
  },
  [ActivityFieldName.CREATOR]: {
    id: "activity.field.creator",
    defaultMessage: "Creator",
    description: "Creator field of activity",
  },
  [ActivityFieldName.CREATOR_EMAIL]: {
    id: "activity.field.creatorEmail",
    defaultMessage: "Creator Email",
    description: "Creator Email field of activity",
  },
  [ActivityFieldName.CREATOR_NAME]: {
    id: "activity.field.creatorName",
    defaultMessage: "Creator Name",
    description: "Creator Name field of activity",
  },
  [ActivityFieldName.CRM_LINK]: {
    id: "activity.field.crmLink",
    defaultMessage: "CRM Link",
    description: "CRM link field of activity",
  },
  [ActivityFieldName.DEAL]: {
    id: "activity.field.deal",
    defaultMessage: "Deal",
    description: "Deal field of activity",
  },
  [ActivityFieldName.DEAL_IMPORT_ID]: {
    id: "activity.field.deal.importId",
    defaultMessage: "Deal ID",
    description: "DealId field of activity",
  },
  [ActivityFieldName.DURATION]: {
    id: "activity.field.duration",
    defaultMessage: "Duration",
    description: "Duration field of activity",
  },
  [ActivityFieldName.END_AT]: {
    id: "activity.field.endAt",
    defaultMessage: "End At",
    description: "End at field of activity",
  },
  [ActivityFieldName.END_DATE]: {
    id: "activity.field.endDate",
    defaultMessage: "End Date",
    description: "End date field of activity",
  },
  [ActivityFieldName.END_TIME]: {
    id: "activity.field.endTime",
    defaultMessage: "End Time",
    description: "End time field of activity",
  },
  [ActivityFieldName.FILES]: {
    id: "activity.field.files",
    defaultMessage: "Files",
    description: "Files field of activity",
  },
  [ActivityFieldName.FREQUENCY]: {
    id: "activity.field.frequency",
    defaultMessage: "Frequency",
    description: "Frequency field of activity",
  },
  [ActivityFieldName.GEOPOINT_CHECK_IN]: {
    id: "activity.field.geopointCheckIn",
    defaultMessage: "Location at Check-In",
    description: "GeoPoint check in field of activity",
  },
  [ActivityFieldName.NAME]: {
    id: "activity.field.name",
    defaultMessage: "Activity Name",
    description: "Name field of activity",
  },
  [ActivityFieldName.NOTE]: {
    id: "activity.field.note",
    defaultMessage: "Note",
    description: "Note field of activity",
  },
  [ActivityFieldName.OWNER_EMAIL]: {
    id: "activity.field.userEmail",
    defaultMessage: "Owner Email",
    description: "Creator Email field of activity",
  },
  [ActivityFieldName.OWNER_NAME]: {
    id: "activity.field.userName",
    defaultMessage: "Owner Name",
    description: "Owner name field of activity",
  },
  [ActivityFieldName.OWNER_TEAM_NAME]: {
    id: "activity.field.userTeamName",
    defaultMessage: "Owner Team Name",
    description: "Creator team name field of activity",
  },
  [ActivityFieldName.PERSON]: {
    id: "activity.field.contact",
    defaultMessage: "Person",
    description: "Contact field of activity",
  },
  [ActivityFieldName.PRIMARY_ASSOCIATION]: {
    id: "activity.field.primaryAssociation",
    defaultMessage: "Primary Association",
    description: "DealId field of activity",
  },
  [ActivityFieldName.QUICK_FILTER]: {
    id: "activity.field.quickFilter",
    defaultMessage: "",
    description: "Quick filter field of activity",
  },
  [ActivityFieldName.RELIABILITY]: {
    id: "activity.field.reliability",
    defaultMessage: "Verified",
    description: "Reliability field of activity",
  },
  [ActivityFieldName.SOURCE]: {
    id: "activity.field.source",
    defaultMessage: "Source",
    description: "Source field of activity",
  },
  [ActivityFieldName.START_AT]: {
    id: "activity.field.startAt",
    defaultMessage: "Date of activity",
    description: "Start at field of activity",
  },
  [ActivityFieldName.START_DATE]: {
    id: "activity.field.startDate",
    defaultMessage: "Start Date",
    description: "Start date field of activity",
  },
  [ActivityFieldName.START_TIME]: {
    id: "activity.field.startTime",
    defaultMessage: "Start Time",
    description: "Start time field of activity",
  },
  [ActivityFieldName.STATUS]: {
    id: "activity.field.status",
    defaultMessage: "Status",
    description: "Status field of activity",
  },
  [ActivityFieldName.TEAM]: {
    id: "activity.field.team",
    defaultMessage: "Team",
    description: "Team field of activity",
  },
  [ActivityFieldName.UNIQUE_ID]: {
    id: "activity.field.uniqueId",
    defaultMessage: "Unique ID",
    description: "Unique id field of activity",
  },
  [ActivityFieldName.UPDATED_AT]: {
    id: "activity.field.updatedAt",
    defaultMessage: "Date Last Modified",
    description: "Updated at field of activity",
  },
  [ActivityFieldName.USER]: {
    id: "activity.field.user",
    defaultMessage: "Owner",
    description: "User field of activity",
  },
  [ActivityFieldName.VISIBILITY]: {
    id: "activity.field.visibility",
    defaultMessage: "Visibility",
    description: "Visibility field of activity",
  },
});

const additionalDisplayNames = defineMessages({
  companyId: {
    id: "activity.field.account.importName",
    defaultMessage: "Company - ID",
    description: "Import name for the account field of activity",
  },
  dealId: {
    id: "activity.field.deal.importName",
    defaultMessage: "Deal - ID",
    description: "Import name for the deal field of activity",
  },
  personId: {
    id: "activity.field.contact.importName",
    defaultMessage: "Person - ID",
    description: "Import name for the contact field of activity",
  },
});

const activityFieldModel = new FieldModel(
  [
    new ActivityNameField({
      displayName: displayNames[ActivityFieldName.NAME],
      formProperties: { fullWidth: false },
      importProperties: { required: true },
      name: ActivityFieldName.NAME,
    }),
    {
      customFilterConfig: {
        defaultInstance: "entity",
        filterInstances: { entity: getEntityFilter(EntityType.COMPANY) },
        operators: ENTITY_FILTER_OPERATORS,
      },
      customGridProperties: {
        cellRenderer: "relationshipCellRenderer",
      },
      displayName: displayNames[ActivityFieldName.COMPANY],
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.RELATIONSHIPS,
      ],
      filterType: FieldType.ID,
      importProperties: {
        displayName: additionalDisplayNames.companyId,
        name: "accountId",
        required: false,
      },
      name: ActivityFieldName.COMPANY,
      platformFilterName: "account.id",
      platformName: "accountId",
      type: FieldType.STRING,
      valueGetter: ["account", "name"],
    },
    {
      customFilterConfig: {
        defaultInstance: "entity",
        filterInstances: { entity: getEntityFilter(EntityType.PERSON) },
        operators: ENTITY_FILTER_OPERATORS,
      },
      customGridProperties: {
        cellRenderer: "relationshipCellRenderer",
      },
      displayName: displayNames[ActivityFieldName.PERSON],
      features: [FieldFeature.FILTERABLE, FieldFeature.RELATIONSHIPS],
      filterType: FieldType.ID,
      importProperties: {
        displayName: additionalDisplayNames.personId,
        name: "contactId",
        required: false,
      },
      name: ActivityFieldName.PERSON,
      platformFilterName: "contact.id",
      platformName: "contactId",
      type: FieldType.STRING,
      valueGetter: ["contact", "name"],
    },
    {
      customFilterConfig: {
        defaultInstance: "entity",
        filterInstances: { entity: getEntityFilter(EntityType.DEAL) },
        operators: ENTITY_FILTER_OPERATORS,
      },
      customGridProperties: {
        cellRenderer: "relationshipCellRenderer",
      },
      displayName: displayNames[ActivityFieldName.DEAL],
      features: [FieldFeature.FILTERABLE, FieldFeature.RELATIONSHIPS],
      filterType: FieldType.ID,
      importProperties: {
        displayName: additionalDisplayNames.dealId,
        name: "dealId",
        required: false,
      },
      name: ActivityFieldName.DEAL,
      platformFilterName: "deal.id",
      platformName: "dealId",
      type: FieldType.STRING,
      valueGetter: ["deal", "name"],
    },
    {
      customGridProperties: {
        cellRenderer: "primaryAssociationCellRenderer",
      },
      displayName: displayNames[ActivityFieldName.PRIMARY_ASSOCIATION],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.PRIMARY_ASSOCIATION,
      type: FieldType.OBJECT,
    },
    new ActivityTypeField({
      displayName: displayNames[ActivityFieldName.ACTIVITY_TYPE],
      name: ActivityFieldName.ACTIVITY_TYPE,
    }),
    new UserField({
      displayName: displayNames[ActivityFieldName.CREATOR],
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.CREATOR,
      platformFilterName: "createdBy",
      platformName: "createdBy",
    }),
    new UserField({
      displayName: displayNames[ActivityFieldName.CREATOR_NAME],
      exportColumnName: "creator",
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      filterName: "creator",
      name: ActivityFieldName.CREATOR_NAME,
      platformFilterName: "createdBy",
      valueGetter: (entity: unknown) => (entity as Activity).creator,
    }),
    {
      displayName: displayNames[ActivityFieldName.CREATOR_EMAIL],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.CREATOR_EMAIL,
      type: FieldType.STRING,
    },
    new UserField({
      displayName: displayNames[ActivityFieldName.USER],
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.OWNER_FIELD,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      importProperties: {
        required: false,
      },
      name: ActivityFieldName.USER,
      platformFilterName: "userId",
      platformName: "userId",
    }),
    new UserField({
      displayName: displayNames[ActivityFieldName.OWNER_NAME],
      exportColumnName: "user",
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.OWNER_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      filterName: "user",
      name: ActivityFieldName.OWNER_NAME,
      platformFilterName: "userId",
      valueGetter: (entity: unknown) => (entity as Activity).user,
    }),
    {
      displayName: displayNames[ActivityFieldName.OWNER_EMAIL],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.OWNER_EMAIL,
      type: FieldType.STRING,
    },
    {
      displayName: displayNames[ActivityFieldName.OWNER_TEAM_NAME],
      exportColumnName: "teams",
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.OWNER_TEAM_NAME,
      type: FieldType.OBJECT,
      valueFormatter: teamsFormatter,
      valueGetter: (entity: unknown) => getUserTeams((entity as Activity).user.id),
    },
    {
      displayName: displayNames[ActivityFieldName.COMPLETED],
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.COMPLETED,
      type: FieldType.BOOLEAN,
    },
    {
      displayName: displayNames[ActivityFieldName.VISIBILITY],
      name: ActivityFieldName.VISIBILITY,
      overridePhiSupport: false,
      type: FieldType.STRING,
    },
    new DateTimeField({
      displayName: displayNames[ActivityFieldName.CREATED_AT],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
      ],
      name: ActivityFieldName.CREATED_AT,
    }),
    new DateTimeField({
      displayName: displayNames[ActivityFieldName.UPDATED_AT],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
      ],
      name: ActivityFieldName.UPDATED_AT,
    }),
    new DateTimeField({
      customFilterConfig: {
        ...defaultFilters[FieldType.DATE_TIME],
        filterInstances: {
          ...defaultFilters[FieldType.DATE_TIME]?.filterInstances,
          empty: EmptyFilter,
        },
        operators: [
          ...(defaultFilters[FieldType.DATE_TIME]?.operators ?? []),
          { instance: "empty", operator: FilterOperator.EMPTY },
        ],
      },
      displayName: displayNames[ActivityFieldName.START_AT],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.VISIBLE_BY_DEFAULT,
      ],
      name: ActivityFieldName.START_AT,
    }),
    new DateField({
      displayName: displayNames[ActivityFieldName.START_DATE],
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      filterName: "startAt",
      name: ActivityFieldName.START_DATE,
      valueFormatter: dateFormatter,
      valueGetter: (entity: unknown) =>
        (entity as Activity).startAt ? parseApiDate((entity as Activity).startAt!) : undefined,
    }),
    {
      displayName: displayNames[ActivityFieldName.START_TIME],
      features: [
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.START_TIME,
      type: FieldType.STRING,
      valueFormatter: timeFormatter,
      valueGetter: (entity: unknown) =>
        (entity as Activity).startAt ? parseApiDate((entity as Activity).startAt!) : undefined,
    },
    new DateTimeField({
      displayName: displayNames[ActivityFieldName.END_AT],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.VISIBLE_BY_DEFAULT,
      ],
      name: ActivityFieldName.END_AT,
    }),
    new DateField({
      displayName: displayNames[ActivityFieldName.END_DATE],
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      filterName: "endAt",
      name: ActivityFieldName.END_DATE,
      valueFormatter: dateFormatter,
      valueGetter: (entity: unknown) =>
        (entity as Activity).endAt ? parseApiDate((entity as Activity).endAt!) : undefined,
    }),
    {
      displayName: displayNames[ActivityFieldName.END_TIME],
      features: [
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.END_TIME,
      type: FieldType.STRING,
      valueFormatter: timeFormatter,
      valueGetter: (entity: unknown) =>
        (entity as Activity).endAt ? parseApiDate((entity as Activity).endAt!) : undefined,
    },
    new SourceField({
      displayName: displayNames[ActivityFieldName.SOURCE],
      name: ActivityFieldName.SOURCE,
    }),
    new DateTimeField({
      displayName: displayNames[ActivityFieldName.COMPLETED_AT],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.COMPLETED_AT,
    }),
    new DateField({
      displayName: displayNames[ActivityFieldName.COMPLETED_DATE],
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      filterName: "completedAt",
      name: ActivityFieldName.COMPLETED_DATE,
      platformFilterName: "completedAt", // we can't show completedAt field in exports, but we can mimic this field to be it when filtering
      valueFormatter: dateFormatter,
      valueGetter: (entity: unknown) =>
        (entity as Activity).completedAt
          ? parseApiDate((entity as Activity).completedAt!)
          : undefined,
    }),
    {
      displayName: displayNames[ActivityFieldName.COMPLETED_TIME],
      features: [
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.COMPLETED_TIME,
      type: FieldType.STRING,
      valueFormatter: timeFormatter,
      valueGetter: (entity: unknown) =>
        (entity as Activity).completedAt
          ? parseApiDate((entity as Activity).completedAt!)
          : undefined,
    },
    {
      displayName: displayNames[ActivityFieldName.STATUS],
      features: [
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.STATUS,
      type: FieldType.STRING,
      valueFormatter: (entity: unknown, value: unknown) => {
        const status = value as ActivityStatus;
        return i18nService.getIntl()
          ? getActivityStatusDisplayName(i18nService.getIntl()!, status)
          : "";
      },
      valueGetter: (entity: unknown) => getActivityStatus(entity as Activity),
    },
    new ActivityNoteField({
      customGridProperties: {
        cellRenderer: "noteCellRenderer",
        tooltipValueGetter: () => "", // suppress tooltip
      },
      displayName: displayNames[ActivityFieldName.NOTE],
      features: [FieldFeature.VISIBLE_BY_DEFAULT],
      formProperties: {
        fullWidth: true,
      },
      name: ActivityFieldName.NOTE,
      overridePhiSupport: false,
      type: FieldType.STRING,
    }),
    new UserField({
      displayName: displayNames[ActivityFieldName.ASSIGNEE],
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.REQUIRED,
      ],
      importProperties: { name: "assigneeId" },
      name: ActivityFieldName.ASSIGNEE,
      platformFilterName: "assigneeId",
      platformName: "assigneeId",
    }),
    {
      displayName: displayNames[ActivityFieldName.ASSIGNEE_NAME],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.ASSIGNEE_NAME,
      type: FieldType.STRING,
    },
    {
      displayName: displayNames[ActivityFieldName.ASSIGNEE_EMAIL],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.ASSIGNEE_EMAIL,
      type: FieldType.STRING,
    },
    {
      displayName: displayNames[ActivityFieldName.ASSIGNEE_TEAM_NAME],
      exportColumnName: "assigneeTeams",
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.ASSIGNEE_TEAM_NAME,
      type: FieldType.OBJECT,
      valueFormatter: teamsFormatter,
      valueGetter: (entity: unknown) => getUserTeams((entity as Activity).assignee?.id),
    },
    {
      customFilterConfig: {
        defaultInstance: "duration",
        filterInstances: {
          duration: DurationFilter,
          durationRange: DurationRangeFilter,
          empty: EmptyFilter,
        },
        operators: [
          ...DURATION_FILTER_OPERATORS,
          { instance: "durationRange", operator: FilterOperator.IN_RANGE },
          { instance: "empty", operator: FilterOperator.EMPTY },
        ],
      } as IHumanReadableFilterConfig,
      displayName: displayNames[ActivityFieldName.DURATION],
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.DURATION,
      type: FieldType.NUMBER,
    },
    new UniqueIdField({
      displayName: displayNames[ActivityFieldName.CRM_LINK],
      features: [
        FieldFeature.EXTERNAL_URL_FIELD,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.DETAILS,
      ],
      name: ActivityFieldName.CRM_LINK,
    }),
    new UniqueIdField({
      displayName: displayNames[ActivityFieldName.UNIQUE_ID],
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.DETAILS,
      ],
      name: ActivityFieldName.UNIQUE_ID,
    }),
    {
      displayName: displayNames[ActivityFieldName.FREQUENCY],
      features: [
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.FREQUENCY,
      type: FieldType.STRING,
    },
    new CheckInLocationField({
      displayName: displayNames[ActivityFieldName.GEOPOINT_CHECK_IN],
      name: ActivityFieldName.GEOPOINT_CHECK_IN,
    }),
    {
      displayName: displayNames[ActivityFieldName.CHECK_IN_DISTANCE],
      features: [
        FieldFeature.CHECK_IN,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.CHECK_IN_DISTANCE,
      type: FieldType.NUMBER,
      valueFormatter: distanceFormatter,
    },
    {
      customGridProperties: {
        cellRenderer: "reliabilityCellRenderer",
      },
      displayName: displayNames[ActivityFieldName.RELIABILITY],
      features: [
        FieldFeature.CHECK_IN,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.RELIABILITY,
      type: FieldType.BOOLEAN,
    },
    // filter-only fields:
    {
      displayName: displayNames[ActivityFieldName.QUICK_FILTER],
      features: [
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: ActivityFieldName.QUICK_FILTER,
      type: FieldType.STRING,
    },
    new TeamField({
      displayName: displayNames[ActivityFieldName.TEAM],
      name: ActivityFieldName.TEAM,
      platformFilterName: "teamId",
    }),
    {
      displayName: addressFieldsMessages.companyStreet,
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.COMPANY_ADDRESS_FIELD,
      ],
      name: "account.address",
      type: FieldType.STRING,
      valueGetter: ["account", "address"],
    },
    {
      displayName: displayNames[ActivityFieldName.ACCOUNT_IMPORT_ID],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: "account.importId",
      type: FieldType.STRING,
      valueGetter: ["account", "importId"],
    },
    {
      displayName: displayNames[ActivityFieldName.CONTACT_IMPORT_ID],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: "contact.importId",
      type: FieldType.STRING,
      valueGetter: ["contact", "importId"],
    },
    {
      displayName: displayNames[ActivityFieldName.DEAL_IMPORT_ID],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: "deal.importId",
      type: FieldType.STRING,
      valueGetter: ["deal", "importId"],
    },
    {
      displayName: addressFieldsMessages.companyCity,
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.COMPANY_ADDRESS_FIELD,
      ],
      name: "account.city",
      type: FieldType.STRING,
      valueGetter: ["account", "city"],
    },
    {
      displayName: addressFieldsMessages.companyState,
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.COMPANY_ADDRESS_FIELD,
      ],
      name: "account.region",
      type: FieldType.STRING,
      valueGetter: ["account", "region"],
    },
    {
      customGridProperties: {
        cellRenderer: "countryCellRenderer",
      },
      displayName: addressFieldsMessages.companyCountry,
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.COMPANY_ADDRESS_FIELD,
      ],
      name: "account.country",
      type: FieldType.STRING,
      valueGetter: ["account", "countryCode"],
    },
    {
      displayName: addressFieldsMessages.companyPostalCode,
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.COMPANY_ADDRESS_FIELD,
      ],
      name: "account.postalCode",
      type: FieldType.STRING,
      valueGetter: ["account", "postalCode"],
    },
    {
      displayName: addressFieldsMessages.peopleStreet,
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.PEOPLE_ADDRESS_FIELD,
      ],
      name: "contact.address",
      type: FieldType.STRING,
      valueGetter: ["contact", "address"],
    },
    {
      displayName: addressFieldsMessages.peopleCity,
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.PEOPLE_ADDRESS_FIELD,
      ],
      name: "contact.city",
      type: FieldType.STRING,
      valueGetter: ["contact", "city"],
    },
    {
      displayName: addressFieldsMessages.peopleState,
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.PEOPLE_ADDRESS_FIELD,
      ],
      name: "contact.region",
      type: FieldType.STRING,
      valueGetter: ["contact", "region"],
    },
    {
      customGridProperties: {
        cellRenderer: "countryCellRenderer",
      },
      displayName: addressFieldsMessages.peopleCountry,
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.PEOPLE_ADDRESS_FIELD,
      ],
      name: "contact.country",
      type: FieldType.STRING,
      valueGetter: ["contact", "countryCode"],
    },
    {
      displayName: addressFieldsMessages.peoplePostalCode,
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.PEOPLE_ADDRESS_FIELD,
      ],
      name: "contact.postalCode",
      type: FieldType.STRING,
      valueGetter: ["contact", "postalCode"],
    },
    {
      displayName: displayNames[ActivityFieldName.FILES],
      features: [
        FieldFeature.FILE_FIELD,
        // not visible anywhere, except in forms
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_MAP_VIEW,
      ],
      formProperties: {
        fullWidth: true,
      },
      name: ActivityFieldName.FILES,
      type: FieldType.LIST,
    },
  ].map(createField)
);

export default activityFieldModel;
