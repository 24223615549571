import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import * as Sentry from "@sentry/react";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";

import configService from "./config/ConfigService";
import * as serviceWorker from "./serviceWorker";
// store be imported before other code to avoid runtime errors
// eslint-disable-next-line perfectionist/sort-imports
import reduxStore from "./store";
import polyfill from "./util/polyfills";
// eslint-disable-next-line perfectionist/sort-imports
import { basepath } from "@app/enum/Path";
import { getLocalTimeZone } from "@app/util/dates";

import "./styles/index.scss";

import { formatDate } from "@app/util/formatters";
import { parseApiDateWithTz } from "@app/util/parsers";
import {
  checkIfOpenedFromMobileApp,
  logoutIfAskedFor,
  readTokenFromUrlIfPresent,
  rememberRedirectLink,
} from "@app/util/startup";

Sentry.init({
  dsn: configService.getSentryDsn(),
  environment: configService.getCurrentEnvironment(),
  integrations: [Sentry.browserTracingIntegration()],
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/.*\.mapmycustomers\.io/,
    /^https:\/\/app2\.mapmycustomers\.me/,
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
});

const history = createBrowserHistory({ basename: basepath });
reduxStore.createStore(history);

const render = () => {
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  const App = require("./App").default;

  ReactDOM.render(
    <Sentry.ErrorBoundary>
      <Provider store={reduxStore.store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </Sentry.ErrorBoundary>,
    document.getElementById("root")
  );
};

polyfill().then(() => {
  fetch("https://mapmycustomers.com/wp-content/uploads/2022/08/logo.svg")
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader();
      const promise = new Promise((resolve) => {
        reader.onload = () => resolve(reader.result);
      });
      reader.readAsDataURL(blob);
      return promise;
    })
    .then((dataUrl) => {
      console.log(
        "%c                           ",
        `margin-top: 10px; font-size:100px; background:url(${dataUrl}) no-repeat;`
      );
    })
    .finally(() => {
      const [versionNumber, buildDate] = configService.getVersion().split(/\s*@\s*/);
      const date = parseApiDateWithTz(buildDate, getLocalTimeZone());
      console.log(`%c👋 v.${versionNumber} @ ${formatDate(date, "Pp")}`, "font-size: 64px;");
    });
  readTokenFromUrlIfPresent();
  logoutIfAskedFor();
  rememberRedirectLink();
  checkIfOpenedFromMobileApp();
  render();
});

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
