import omit from "lodash-es/omit";
import { Action } from "redux";
import { fork, put, select, takeLatest } from "redux-saga/effects";
import { isActionOf } from "typesafe-actions";

import { EntityType } from "@mapmycustomers/shared/enum";
import DealStageType from "@mapmycustomers/shared/enum/DealStageType";
import { MapEntry } from "@mapmycustomers/shared/types/map";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import PlatformListRequest from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformListRequest";

import HeatMapLayerData from "@app/scene/map/store/layers/heatMap/HeatMapLayerData";
import { getHeatMapLayerData, getMapViewport } from "@app/scene/map/store/selectors";
import getPrecision from "@app/scene/map/utils/getPrecision";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId, isBigOrganization } from "@app/store/iam";
import { updateMetadata } from "@app/store/iam/actions";
import MapViewportState from "@app/types/map/MapViewportState";
import { getLocalTimeZone } from "@app/util/dates";
import { formatDate } from "@app/util/formatters";

import { toggleLayerLegendExpandedState, toggleLayerVisibility } from "../actions";

import {
  fetchHeatMapData,
  updateHeatMapActivityTypes,
  updateHeatMapDateRange,
  updateHeatMapFunnels,
  updateHeatMapOpenStages,
  updateHeatMapRange,
  updateHeatMapStageTypes,
  updateHeatMapType,
} from "./actions";

export function* onFetchHeatMapRange() {
  // TODO: discuss min and max detection on the meeting
  // try {
  //   const layerData: HeatMapLayerData = yield select(getHeatMapLayerData);
  //   if (!layerData.visible) {
  //     return;
  //   }
  //
  //   const requestPayload = getRequestPayload(layerData);
  //   requestPayload.$filters!.precision = 1; // fetch data for the whole map
  //   const orgId: Organization["id"] = yield select(getOrganizationId);
  //   const response: ListResponse<MapEntry> = yield callApi(
  //     layerData.type === EntityType.DEAL ? "fetchDeals" : "fetchActivities",
  //     orgId,
  //     requestPayload
  //   );
  //   console.log("response=", response);
  //
  //   if (response.data.length) {
  //   } else {
  //     yield put(updateHeatMapRange({ min: 0, max: 0 }));
  //   }
  // } catch (e) {
  //   loggingService.error("Failed to initialize heat map", e);
  // }
}

function getRequestPayload(
  layerData: HeatMapLayerData,
  isBigOrganization: boolean,
  viewport?: MapViewportState
) {
  // we should only include DEFAULT into stageTypes filter when there's more than one funnel
  // in filters or open stages are not specified
  const stageTypes = layerData.stageTypes.filter((type) => type !== DealStageType.DEFAULT);
  if (
    layerData.stageTypes.includes(DealStageType.DEFAULT) &&
    (layerData.funnelIds.length !== 1 || !layerData.openStageIds.length)
  ) {
    stageTypes.push(DealStageType.DEFAULT);
  }

  const requestPayload: PlatformListRequest = {
    $filters: {
      ...(viewport
        ? {
            bounds: viewport.bounds,
            precision: Math.max(15, getPrecision(viewport.zoom ?? 1, isBigOrganization)), // fetch data with precision >= 15
            precisionThreshold: 1000,
          }
        : {}),
      ...{
        [layerData.type === EntityType.DEAL ? "closingDate" : "completedAt"]: {
          // when dateRange is not defined, i.e. All Time is selected,
          // we limit the end range by current time
          $gte: layerData.dateRange
            ? formatDate(layerData.dateRange.startDate, "yyyy-MM-dd")
            : undefined,
          $lte: layerData.dateRange
            ? formatDate(layerData.dateRange.endDate, "yyyy-MM-dd")
            : new Date().toISOString().substring(0, 10),
          // we don't need timezone for closingDate field, which is a Date field, not DateTime
          $timezone: layerData.type === EntityType.DEAL ? undefined : getLocalTimeZone(),
        },
      },
      ...(layerData.type === EntityType.ACTIVITY
        ? {
            completed: true,
            ...(layerData.activityTypeIds.length
              ? { crmActivityTypeId: { $in: layerData.activityTypeIds } }
              : {}),
          }
        : {}),
      ...(layerData.type === EntityType.DEAL
        ? {
            ...(layerData.funnelIds.length ? { funnelId: { $in: layerData.funnelIds } } : {}),
            ...{
              $or: [
                ...(stageTypes ? [{ "stage.type": { $in: stageTypes } }] : []),
                ...(layerData.openStageIds.length &&
                layerData.funnelIds.length === 1 &&
                layerData.stageTypes.includes(DealStageType.DEFAULT)
                  ? [{ stageId: { $in: layerData.openStageIds } }]
                  : []),
              ],
            },
          }
        : {}),
      includeAccessStatus: true,
    },
    $limit: 10000, // fetch all available data
  };
  return requestPayload;
}

export function* onFetchHeatMapData() {
  try {
    const layerData: HeatMapLayerData = yield select(getHeatMapLayerData);
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const viewport: MapViewportState = yield select(getMapViewport);

    if (!layerData.visible) {
      yield put(fetchHeatMapData.success([]));
      return;
    }

    yield fork(onPersistHeatMapSettings);

    if (
      !viewport.bounds?.top_left ||
      !viewport.bounds?.bottom_right ||
      (viewport.bounds.top_left[0] === viewport.bounds.bottom_right[0] &&
        viewport.bounds.top_left[1] === viewport.bounds.bottom_right[1])
    ) {
      // not really a success, but we can't fetch data for empty bounds
      yield put(fetchHeatMapData.success([]));
      return;
    }

    const bigOrganization: boolean = yield select(isBigOrganization);
    const requestPayload = getRequestPayload(layerData, bigOrganization, viewport);

    const response: ListResponse<MapEntry> = yield callApi(
      layerData.type === EntityType.DEAL ? "fetchDeals" : "fetchActivities",
      orgId,
      requestPayload
    );

    if (layerData.type === EntityType.ACTIVITY) {
      const max = response.data.reduce((result, pin) => Math.max(result, pin.count), 0);
      yield put(updateHeatMapRange({ max, min: 0 }));
    } else {
      const max = response.data.reduce((result, pin) => Math.max(result, pin.sumAmount ?? 0), 0);
      yield put(updateHeatMapRange({ max, min: 0 }));
    }

    yield put(fetchHeatMapData.success(response.data));
  } catch (error) {
    yield put(fetchHeatMapData.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onPersistHeatMapSettings() {
  const layerData: HeatMapLayerData = yield select(getHeatMapLayerData);
  yield put(updateMetadata.request({ heatMapSettings: omit(layerData, "pins") }));
}

const isToggleVisibilityOrLegendAction = isActionOf([
  toggleLayerVisibility,
  toggleLayerLegendExpandedState,
]);

const heatMapFilteringActions = [
  updateHeatMapDateRange,
  updateHeatMapType,
  updateHeatMapActivityTypes,
  updateHeatMapFunnels,
  updateHeatMapStageTypes,
  updateHeatMapOpenStages,
];

export function* heatMapLayerSagas() {
  yield takeLatest([fetchHeatMapData.request, ...heatMapFilteringActions], onFetchHeatMapData);
  yield takeLatest(
    (action: Action) =>
      (isActionOf(toggleLayerVisibility, action) &&
        action.payload.name === "heatMap" &&
        action.payload.visible) ||
      isActionOf(heatMapFilteringActions, action),
    onFetchHeatMapRange
  );
  yield takeLatest(
    (action: Action) =>
      isToggleVisibilityOrLegendAction(action) && action.payload.name === "heatMap",
    onPersistHeatMapSettings
  );
}
