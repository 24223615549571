import React, { ReactNode, useCallback } from "react";
import { connect } from "react-redux";

import Form from "antd/es/form";
import { useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { defineMessages, useIntl } from "react-intl";

import { Company, EntityType } from "@mapmycustomers/shared/types/entity";
import { CompanyRef } from "@mapmycustomers/shared/types/entity/Company";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { LoadingSpinner } from "@mapmycustomers/ui";

import CompanyAssociation from "@app/component/associations/CompanyAssociation";
import { fetchCompanies } from "@app/store/company/actions";
import { PersonFieldName } from "@app/util/fieldModel/PersonFieldModel";

import CompaniesSelectInput from "./CompaniesSelectInput";

const messages = defineMessages({
  label: {
    id: "formFields.selectParentCompany.forPerson",
    defaultMessage: "Companies",
    description: "Parent Companies field",
  },
  modalTitle: {
    id: "formFields.selectParentCompany.forPerson.modal.title",
    defaultMessage: "Select Companies",
    description: "Title of the Select Parent Companies modal",
  },
});

interface Props {
  allowAdd?: boolean;
  disabled?: boolean;
  label?: ReactNode;
  onChange?: (companies: Company[]) => void;
  onChangePrimaryCompany?: (accountId?: Company["id"]) => void;
  onFetchCompanies: typeof fetchCompanies;
  primaryCompanyId?: Company["id"];
  required?: boolean;
}

const PersonCompanyField: React.FC<Props> = ({
  allowAdd,
  disabled,
  label,
  onChange,
  onChangePrimaryCompany,
  onFetchCompanies,
  primaryCompanyId,
  required,
}) => {
  const intl = useIntl();
  const form = useFormInstance();
  const assignedCompanies: CompanyRef[] = useWatch(PersonFieldName.COMPANIES, form);

  const [modalVisible, showModal, hideModal] = useBoolean();
  const [detailsLoading, startLoading, stopLoading] = useBoolean();

  const handleParentCompaniesHide = useCallback(
    (selectedCompaniesIds: Company["id"][]) => {
      if (selectedCompaniesIds.length > 0) {
        startLoading();
        onFetchCompanies({
          callback: (companies: Company[]) => {
            stopLoading();
            form.setFieldValue(PersonFieldName.COMPANIES, companies);
            onChange?.(companies);
          },
          failureCallback: stopLoading,
          ids: selectedCompaniesIds,
        });
      } else {
        form.setFieldValue(PersonFieldName.COMPANIES, []);
        onChange?.([]);
      }
    },
    [form, onChange, onFetchCompanies, startLoading, stopLoading]
  );
  return (
    <>
      <Form.Item
        label={label ?? intl.formatMessage(messages.label)}
        name={PersonFieldName.COMPANIES}
        required={required}
        rules={[{ required }]}
      >
        <CompaniesSelectInput
          disabled={disabled}
          onClick={showModal}
          open={false}
          primaryCompanyId={primaryCompanyId}
          suffixIcon={detailsLoading ? <LoadingSpinner mini /> : undefined}
        />
      </Form.Item>

      {modalVisible && (
        <CompanyAssociation
          allowAdd={allowAdd}
          assignedCompanies={assignedCompanies}
          entityType={EntityType.PERSON}
          multiselect
          onHide={hideModal}
          onSelect={handleParentCompaniesHide}
          onUpdatePrimaryCompany={onChangePrimaryCompany}
          primaryCompanyId={primaryCompanyId}
          supportsPrimaryEntity
          title={label ?? intl.formatMessage(messages.modalTitle)}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  onFetchCompanies: fetchCompanies,
};

export default connect(null, mapDispatchToProps)(PersonCompanyField);
